import { template as template_d25aaaba0861492a9d7c29624ff40283 } from "@ember/template-compiler";
const WelcomeHeader = template_d25aaaba0861492a9d7c29624ff40283(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
