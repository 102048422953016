import { template as template_c51f4f1f52944cfaae7aafe761c20dbc } from "@ember/template-compiler";
const FKLabel = template_c51f4f1f52944cfaae7aafe761c20dbc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
