import { template as template_135b2b7774e943c3a6658ed2518d8484 } from "@ember/template-compiler";
const FKControlMenuContainer = template_135b2b7774e943c3a6658ed2518d8484(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
