import { template as template_ad12f9e235944ba9a655cadc6f927319 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ad12f9e235944ba9a655cadc6f927319(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
