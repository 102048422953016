import { template as template_ca664133f6e3401f84c94166081d9263 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ca664133f6e3401f84c94166081d9263(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
